<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1 style="font-weight: bold; text-transform: uppercase">
          {{ this.$route.params.zone_type }}
        </h1>
      </div>
    </div>
    <div class="section-action">
      <v-row class="mt-2">
        <v-col cols="12" md="4" class="mt-4">
          <div class="search-action">
            <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense v-model="searchItem"
              prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')" @keypress.enter="fetchDataGps">
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <label class="label-input">{{
            $t("ReportScanInOut.table.branch")
          }}</label>
          <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
            hide-selected v-model="branch_id" @change="fetchDataGps">
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" class="mt-4">
          <div class="btn-new-create-action">
            <v-btn class="btn-create" @click="onCreate()"> {{ $t("action.create") }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="itemGps.length > 0">
          <thead>
            <tr>
              <th class="text-start">{{ $t("map.no") }}</th>
              <!-- <th class="text-left">{{ $t("map.companyName") }}</th> -->
              <th class="text-left">{{ $t("map.LocationName") }}</th>
              <th class="text-left">{{ $t("map.branch") }}</th>
              <th class="text-left">{{ $t("map.type") }}</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in itemGps" :key="index">
              <td>
                {{ index + 1 }}
              </td>
              <!-- <td>
              {{ item.company_name }}
            </td> -->
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.branch.branch_name }}
              </td>
              <td>
                <div class="d-flex">
                  <div class="show-td-gps align-center mr-4">
                    <h3>
                      <v-btn icon color="pink">
                        <a v-bind:href="'https://www.google.com/maps/dir//' +
                          item.latitude +
                          ',' +
                          item.longitude +
                          '/@' +
                          item.latitude +
                          ',' +
                          item.longitude +
                          ',400m/data=!3m1!1e3!4m2!4m1!3e0'
                          " target="_blank">
                          <span>
                            <i class="fas fa-map-pin"></i>
                          </span>
                          {{ item.zone_type }}
                        </a>
                      </v-btn>
                    </h3>
                  </div>
                  <div class="show-td-gps align-center">
                    <v-btn icon color="pink" @click="modalAddWifi(item.id)">
                      <v-icon>mdi-wifi-marker</v-icon>
                    </v-btn>
                  </div>
                  <div class="show-td-gps align-center mt-1 ml-4">
                    <p v-for="(wifi, index) in item.clock_in_zone_wifi" :key="index">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ wifi.wifi_ip }} <span v-if="wifi.wifi_name" class="text-primary">({{ wifi.wifi_name
                              }})</span>
                          </span>
                        </template>
                        <span>{{ wifi.description }}</span>
                      </v-tooltip>

                      <span><v-btn icon color="pink" @click="DeletedWifi(wifi.id)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn> </span><br />
                    </p>
                  </div>
                </div>
              </td>
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="btn-edit" @click="onEdit(item.id)">{{ $t("action.edit") }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">{{ $t("action.delete") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <default-table-no-result v-else />
        <Loading v-if="isLoading" />
        <ModalDelete>
          <template v-slot="{ close }">
            <DeleteZoneGPS :Gps_id="Gps_id" @close="close" @success="fetchDataGps()" />
          </template>
        </ModalDelete>

        <createZoneGPS :visible="modalAddZoneGPS" @success="fetchDataGps" @change="(val) => (modalAddZoneGPS = val)" />

        <editZoneGPS @success="fetchDataGps" :visible="modalEditZoneGPS" :item="Item"
          @change="(val) => (modalEditZoneGPS = val)" />
        <ModalAddWifi :Gps_id="Gps_id" @success="fetchDataGps" @close="dialog = false" :dialog="dialog" />

        <DeleteWifi :wifi_id="wifi_id" :dialogD="dialogD" @close="dialogD = false" @success="fetchDataGps()" />
      </div>
    </div>
  </div>
</template>

<script>
import createZoneGPS from "./createZoneGPS";
import Loading from "../../Loading";
import DeleteZoneGPS from "./DeleteZoneGPS";
import editZoneGPS from "./editZoneGPS";
import defaultTableNoResult from "../../defaultTableNoResult";
import ModalAddWifi from "./wifi/ModalAddWifi";
import DeleteWifi from "./wifi/DeleteWifi";

export default {
  components: {
    createZoneGPS,
    Loading,
    DeleteZoneGPS,
    editZoneGPS,
    defaultTableNoResult,
    ModalAddWifi,
    DeleteWifi,
  },
  data() {
    return {
      listBranch: [],
      isLoading: false,
      zone: [
        {
          id: 1,
          name: "gps",
          type: "Gps",
        },
        {
          id: 2,
          name: "gps",
          type: "Gps",
        },
        {
          id: 3,
          name: "gps",
          type: "Gps",
        },
      ],
      dialog: false,
      dialogD: false,
      modalAddZoneGPS: false,
      modalEditZoneGPS: false,
      itemGps: [],
      Gps_id: "",
      wifi_id: "",
      Item: {},
      branch_id: "",
      searchItem: "",
    };
  },
  methods: {
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onCreate() {
      if (this.$route.params.zone_type === "Gps") {
        this.modalAddZoneGPS = true;
      }
    },
    modalAddWifi(Gps_id) {
      this.dialog = true;
      this.Gps_id = Gps_id;
    },
    DeletedWifi(wifi_id) {
      this.wifi_id = wifi_id;
      this.dialogD = true;
    },

    filterZoneGPS(gps_Id) {
      return (
        this.itemGps.filter((item) => {
          return item.id == gps_Id;
        })[0] || {}
      );
    },

    onEdit(gps_Id) {
      this.Item = {
        ...this.filterZoneGPS(gps_Id),
      };
      this.modalEditZoneGPS = true;
    },

    fetchDataGps() {
      this.isLoading = true;
      this.$axios
        .get(`company/clock-in-zone`, {
          params: {
            zone_type: this.$route.params.zone_type,
            filter: this.searchItem,
            branch_id: this.branch_id,
          },
        })
        .then((res) => {
          this.itemGps = res.data.data;
          console.log(this.itemGps, "loggggggg");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onDeleted(Gps_id) {
      this.Gps_id = Gps_id;
      this.$store.commit("modalDelete_State", true);
    },
  },

  created() {
    this.fetchDataGps();
    this.fetchBranch();
  },
};
</script>

<style scoped lang="scss">
.show-td-gps {
  h3 {
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    color: #d70f64;

    span {
      color: #d70f64;
    }
  }
}
</style>
